<!-- 销售对账列表 -->
<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class="container"
  >
    <div class="title-container">销售对账列表
      <el-button class="common-screen-btn" type="primary" @click="() => download()">导出报表</el-button>
    </div>
    <div class="line"></div>
    <!-- 筛选条件 -->
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>客户名称:</span>
        <el-input
          v-model="params.customerName"
          placeholder="请输入内容"
          class="common-screen-input"
          clearable
        ></el-input>
      </div>
      <!-- <div class="common-input-container">
        <span>客户类型:</span>
        <el-select
          v-model="params.state"
          placeholder="请选择"
          class="common-screen-input"
          clearable
          @change="commonFun"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->
      <div class="common-input-container">
        <span>订单类型:</span>
        <el-select v-model="params.orderType" placeholder="请选择" class="common-screen-input" clearable @change="commonFun">
          <el-option label="新签" value="1"></el-option>
          <el-option label="续签" value="2"></el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>收款方式:</span>
        <el-select
          v-model="params.recordType"
          placeholder="请选择"
          class="common-screen-input"
          clearable
          @change="commonFun"
        >
          <el-option
            v-for="item in options2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <br />
      <div class="common-input-container">
        <span>应收服务费时间:</span>
        <el-date-picker @change="commonFun" v-model="params.createTime" type="month" value-format="yyyy-MM" placeholder="选择月" class="common-screen-input">
        </el-date-picker>
      </div>
	  <div class="common-input-container">
	    <span>收款状态:</span>
	    <el-select
	      class="common-screen-input"
	      @change="commonFun"
	      v-model="params.financialStatus"
	      placeholder="请选择"
	      filterable
	    >
	      <el-option
	        v-for="item in incomeStatus"
	        :key="item.value"
	        :label="item.label"
	        :value="item.value"
	      >
	      </el-option>
	    </el-select>
	  </div>
      <el-button
        class="common-screen-btn"
        type="primary"
        @click="
          () => {
            commonFun();
          }
        "
        >查 询</el-button
      >
      <el-button class="common-screen-btn" plain @click="() => resetBtn()"
        >重 置</el-button
      >
    </div>
    <div class="gap"></div>
    <!-- 表格 -->
    <div class="common-padding">
      <el-table :data="tableData" style="width: 100%">
      <el-table-column type="index" :label="'序号' + textNum" width="50" fixed></el-table-column>
      <el-table-column prop="customerName" width="140" label="客户名称" fixed>
        <template slot-scope="scope">
          <el-button type="text" @click="() => { goCustomerPool(scope.row) }">
            {{ scope.row.customerName }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="orderName" width="140" label="订单名称" fixed>
        <template slot-scope="scope">
          <el-button type="text" @click="() => { openNewWin(`/customer/total-order?orderNo=${scope.row.orderNo}&orderId=${scope.row.orderId}&from=saleAccount`) }">
            {{ scope.row.orderName || '--' }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="orderTypeName" width="100" :formatter="tableColumn" label="订单类型"></el-table-column>
			<el-table-column prop="financialStatusName" width="100" :formatter="tableColumn" label="付款状态"></el-table-column>
      <el-table-column width="140" :label="'服务周期' + (load ? ' ' : '')">
        <template slot-scope="scope">
            {{ `${scope.row.startTime}\n${scope.row.endTime}` }}
        </template>
      </el-table-column>
      <el-table-column prop="orderDay" width="140" :formatter="tableColumn" label="订单服务天数"></el-table-column>
      <el-table-column :label="month + '月实际应收服务费' + (load ? ' ' : '')" align="center">
        <template slot="header">
          <div>
            <span>{{month + '月实际应收服务费'}}</span>
            <span class="orange">【{{ actualPriceTotal || 0 }}】</span>
          </div>
        </template>
        <el-table-column width="220" prop="actualPrice">
          <template slot="header">
            <el-tooltip class="item" effect="dark" content="当月新签实际应收 = 新签订单的金额 - 当月之前订单收款金额" placement="bottom">
              <div style="display: inline-block;">
                <span>新签实际应收款</span> 
                <img
                  src="~@/assets/images/question.png"
                  class="tit-img"
                />
              </div>
            </el-tooltip>
            <span class="orange">【{{ newActualPriceTotal || 0 }}】</span>
          </template>
          <template slot-scope="scope">
            {{ scope.row.newActualPrice? scope.row.newActualPrice.toFixed(2):'--' }}
          </template>
        </el-table-column>
        <el-table-column width="220" prop="actualPrice">
          <template slot="header">
            <el-tooltip class="item" effect="dark" content="当月续签实际应收 = 续签订单的金额 - 当月之前订单收款金额" placement="bottom">
              <div style="display: inline-block;">
                <span>续签实际应收款</span> 
                <img
                  src="~@/assets/images/question.png"
                  class="tit-img"
                />
              </div>
            </el-tooltip>
            <span class="orange">【{{ renewActualPriceTotal || 0 }}】</span>
          </template>
          <template slot-scope="scope">
            {{ scope.row.renewActualPrice? scope.row.renewActualPrice.toFixed(2):'--' }}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column width="140" prop="price">
        <template slot="header">
          订单原价<br/>
          <span class="orange">【{{ originalPriceTotal || 0 }}】</span>
        </template>
        <template slot-scope="scope">
          {{ scope.row.originalPrice? scope.row.originalPrice.toFixed(2):'0.00'}}
        </template>
      </el-table-column>
      <el-table-column width="140" prop="differencePrice">
        <template slot="header">
          <el-tooltip class="item" effect="dark" content="订单调价统计，计算审批“已通过“的总和" placement="bottom">
              <div>
                <span>订单调价金额</span> 
                <img
                  src="~@/assets/images/question.png"
                  class="tit-img"
                />
              </div>
            </el-tooltip>
          <span class="orange">【{{ differencePriceAmountTotal || 0 }}】</span>
        </template>
        <template slot-scope="scope">
          <el-button @click="openDifferencePrice(scope.row.orderId,'differencePrice')" type="text">{{ scope.row.differencePrice ? Number(scope.row.differencePrice).toFixed(2) : '0.00' }}</el-button>
        </template>
      </el-table-column>
      <el-table-column width="140" prop="collectionAmount" label="收款金额">
        <template slot="header">
          收款金额<br/>
          <span class="orange">【{{ collectionAmountTotal || 0 }}】</span>
        </template>
        <template slot-scope="scope">
          {{ scope.row.collectionAmount?scope.row.collectionAmount.toFixed(2):'0.00' }}
        </template>
      </el-table-column>
      <el-table-column width="140" prop="capacityPrice" label="月产能金额">
        <template slot="header">
          <el-tooltip class="item" effect="dark" content="订单原价月产能 = 订单原价/服务天数*当前月服务天数" placement="bottom">
              <div>
                <span>订单原价月产能</span> 
                <img
                  src="~@/assets/images/question.png"
                  class="tit-img"
                />
              </div>
            </el-tooltip>
          <span class="orange">【{{ originalSettlePriceTotal || 0 }}】</span>
        </template>
        <template slot-scope="scope">
          {{ scope.row.originalSettlePrice?scope.row.originalSettlePrice.toFixed(2):'0.00' }}
        </template>
      </el-table-column>
      <el-table-column width="140" prop="capacityPrice" label="月产能金额">
        <template slot="header">
          <el-tooltip class="item" effect="dark" content="1、历史服务周期的订单调价，引起的产能变化归入调价发起时间所在周期。
2、当期订单调价产能以结算日为界限，结算日前发起的调价，产能=调价金额/调价服务时间*当月服务天数，结算日后发起的调价产能全部计入下一个服务周期产能。
3、服务时间属于待服务的调价，在服务时间所在周期进行结算。" placement="bottom">
              <div>
                <span>订单调价月产能</span> 
                <img
                  src="~@/assets/images/question.png"
                  class="tit-img"
                />
              </div>
            </el-tooltip>
          <span class="orange">【{{ changeSettlePriceTotal || 0 }}】</span>
        </template>
        <template slot-scope="scope">
          <el-button @click="openChangeSettlePriceTotal(scope.row.orderId,'changeSettlePrice')" type="text">{{ scope.row.changeSettlePrice?scope.row.changeSettlePrice.toFixed(2):'0.00' }}</el-button>
        </template>
      </el-table-column>
      <el-table-column width="140" prop="capacityPrice" label="月产能金额">
        <template slot="header">
          <el-tooltip class="item" effect="dark" content="结算月产能 = 订单原价月产能 +订单调价月产能" placement="bottom">
              <div>
                <span>结算月产能金额</span> 
                <img
                  src="~@/assets/images/question.png"
                  class="tit-img"
                />
              </div>
            </el-tooltip>
          <span class="orange">【{{ orderSettlePriceTotal || 0 }}】</span>
        </template>
        <template slot-scope="scope">
          {{ scope.row.orderSettlePrice?scope.row.orderSettlePrice.toFixed(2):'0.00' }}
        </template>
      </el-table-column>
      <el-table-column width="140" prop="serviceDay" :formatter="tableColumn" label="月服务天数"></el-table-column>
      <el-table-column width="140" prop="recordType" label="收款方式">
          <template slot-scope="scope">
          <span v-if="scope.row.recordType == 'bankCard'">银行卡</span>
          <span v-else-if="scope.row.recordType == 'alipay'">支付宝</span>
          <span v-else-if="scope.row.recordType == 'wechat'">微信</span>
          <span v-else-if="scope.row.recordType == 'cash'">现金</span>
          <span v-else-if="scope.row.recordType == 'corporateAccount'">对公账户</span>
          <span v-else-if="scope.row.recordType == 'generalAccount'">对公账户（总）</span>
          <span v-else-if="scope.row.recordType == 'publicAccount'">对公账户（分）</span>
          <span v-else-if="scope.row.recordType == 'taobao'">淘宝</span>
          <span v-else-if="scope.row.recordType == 'tiktok'">抖音</span>
          <span v-else-if="scope.row.recordType == 'pinduoduo'">拼多多</span>
          <span v-else-if="scope.row.recordType == 'other'">其他</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column width="140" prop="confirmRecordTime" label="收款时间">
				<template slot-scope="scope">
					<span>{{scope.row.confirmRecordTime ? formatDateTimer(scope.row.confirmRecordTime) : '--'}}</span>
				</template>
      </el-table-column>
      <el-table-column width="140" :formatter="tableColumn" :show-overflow-tooltip="true" prop="note" label="销售备注"></el-table-column>
      <el-table-column width="140" :formatter="tableColumn" :show-overflow-tooltip="true" prop="remark" label="财务备注"></el-table-column>
      <el-table-column width="140" :formatter="tableColumn" :show-overflow-tooltip="true" prop="settleRemark" label="对账备注"></el-table-column>
      <el-table-column width="140" label="操作">
				<template slot-scope="scope">
					<el-button v-if="scope.row.settleId || scope.row.settleId == 0" type="text" @click="() => { editRemark(scope.row.settleId) }">编辑对账备注</el-button>
					<span v-else></span>
				</template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    </div>
    <el-dialog v-dialogDrag title="编辑对账备注" :append-to-body="true" width="520px" :visible.sync="remarkVisible">
      <el-form :model="ruleForm" ref="ruleForm" label-width="120px">
        <el-form-item label="对账备注：" :rules="[{ required: true, message: '请输入备注', trigger: 'change' }]" prop="settleRemark">
            <el-input :autosize="{ minRows: 6, maxRows: 6 }" type="textarea" v-model="ruleForm.settleRemark" placeholder="对账备注"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button class="common-screen-btn" @click="() => { remarkVisible = false }">取 消</el-button>
          <el-button class="common-screen-btn" type="primary" @click="() => { remarkIsOk() }">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 订单调价金额 -->
    <el-dialog v-dialogDrag title="订单调价金额" :append-to-body="true" width="50%" :visible.sync="priceVisible">
       <el-table v-loading="loading2" element-loading-text="数据较多，拼命加载中..." :data="priceData">
          <el-table-column label="订单编号" show-overflow-tooltip :formatter="tableColumn" width="140">
            <template slot-scope="scope">
              <el-button v-if="scope.row.workOrderName&&scope.row.workOrderId" @click="()=> { openNewWin(`/work-order/approve?workOrderName=${scope.row.workOrderName}&workOrderId=${scope.row.workOrderId}`) }" type="text">{{scope.row.orderNo}}</el-button>
              <el-button v-else type="text" disabled>{{scope.row.orderNo}}</el-button>
            </template>
          </el-table-column>
          <el-table-column property="orderPrice" label="订单应收金额" show-overflow-tooltip :formatter="tableColumn" width="110"></el-table-column>
          <el-table-column label="改价类型">
            <template slot-scope="scope">
              <span>{{scope.row.type||scope.row.type=='0'? '加班费 ':scope.row.type == 1? '咨询量查价':scope.row.type == 2? '增减坐席/人力':scope.row.type == 3?'服务扣款':scope.row.type == 4?'其他':scope.row.type == 5?'服务对接群':'--'}}</span>
            </template>
          </el-table-column>
          <el-table-column property="shopNames" label="店铺" show-overflow-tooltip :formatter="tableColumn"></el-table-column>
          <el-table-column property="startTime" label="时间段" show-overflow-tooltip :formatter="tableColumn">
            <template slot-scope="scope">
              <span>{{scope.row.startTime+'-'+scope.row.endTime}}</span>
            </template>
          </el-table-column>
          <el-table-column property="changeMoneny" label="调价金额" show-overflow-tooltip :formatter="tableColumn" width="100">
            <template slot="header">
              <el-tooltip class="item" effect="dark" content="计算审批“已通过“的总和" placement="bottom">
                <div style="display: inline-block;">
                  <span>调价金额</span> 
                  <img
                    src="~@/assets/images/question.png"
                    class="tit-img"
                  />
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column property="result" label="改价结果" show-overflow-tooltip :formatter="tableColumn"></el-table-column>
          <el-table-column property="remark" label="改价说明" show-overflow-tooltip :formatter="tableColumn"></el-table-column>
          <!-- <el-table-column property="workStatus" label="审批状态">
            <template slot-scope="scope">
              <span :style="{'color': scope.row.workStatus == 1? '#52C419':scope.row.workStatus == 2? '1890ff':scope.row.workStatus == 3? '#FF4D4F':scope.row.workStatus == 4?'#AAAAAA':''}">{{scope.row.workStatus == 1? '处理中':scope.row.workStatus == 2? '已通过':scope.row.workStatus == 3? '已拒绝':scope.row.workStatus == 4?'已撤销':'--'}}</span>
            </template>
          </el-table-column> -->
          <el-table-column property="createName" label="创建人" show-overflow-tooltip :formatter="tableColumn"></el-table-column>
          <el-table-column property="createTime" label="创建时间" show-overflow-tooltip :formatter="tableColumn"></el-table-column>
          <!-- <el-table-column property="groupid" label="最后处理时间" show-overflow-tooltip :formatter="tableColumn"></el-table-column> -->
       </el-table>
       <br/>
       <el-pagination
          @size-change="(e) => pageFun2(e, 'pageSize')"
          @current-change="(e) => pageFun2(e)"
          style="text-align: center"
          :page-sizes="pagination2.pageSizes"
          :page-size="priceParams.pageSize"
          :current-page="priceParams.pageNum"
          layout="total, prev, pager, next,sizes, jumper"
          :total="pagination2.total"
        >
        </el-pagination>
    </el-dialog>

    <!-- 订单调价月产能 -->
    <el-dialog v-dialogDrag title="订单调价月产能" :append-to-body="true" width="50%" :visible.sync="priceVisible2">
       <el-table v-loading="loading3" element-loading-text="数据较多，拼命加载中..." :data="priceData2">
          <el-table-column property="orderNo" label="订单编号" show-overflow-tooltip :formatter="tableColumn" width="140"></el-table-column>
          <el-table-column property="typeName" label="改价类型" :formatter="tableColumn">
          </el-table-column>
          <el-table-column property="shopName" label="店铺" show-overflow-tooltip :formatter="tableColumn"></el-table-column>
          <el-table-column property="startTime" label="时间段" show-overflow-tooltip :formatter="tableColumn">
            <template slot-scope="scope">
              <span>{{scope.row.startTime+'-'+scope.row.endTime}}</span>
            </template>
          </el-table-column>
          <el-table-column property="monthServiceDay" label="月服务天数" show-overflow-tooltip :formatter="tableColumn" width="100"></el-table-column>
          <el-table-column property="changePrice" label="调价金额" show-overflow-tooltip :formatter="tableColumn" width="100">
            <template slot="header">
              <el-tooltip class="item" effect="dark" content="计算审批“已通过“的总和" placement="bottom">
                <div style="display: inline-block;">
                  <span>调价金额</span> 
                  <img
                    src="~@/assets/images/question.png"
                    class="tit-img"
                  />
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column property="changeSettlePrice" label="订单调价月产能" show-overflow-tooltip :formatter="tableColumn" width="140">
            <template slot="header">
              <el-tooltip class="item" effect="dark" content="订单调价月产能 = 调价金额/调价服务天数*当月调价服务天数" placement="bottom">
                <div style="display: inline-block;">
                  <span>订单调价月产能</span> 
                  <img
                    src="~@/assets/images/question.png"
                    class="tit-img"
                  />
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column property="remark" label="改价说明" show-overflow-tooltip :formatter="tableColumn"></el-table-column>
          <el-table-column property="createName" label="创建人" show-overflow-tooltip :formatter="tableColumn"></el-table-column>
          <el-table-column property="createTime" label="创建时间" show-overflow-tooltip :formatter="tableColumn"></el-table-column>
          <!-- <el-table-column property="groupid" label="最后处理时间" show-overflow-tooltip :formatter="tableColumn"></el-table-column> -->
       </el-table>
       <br/>
       <el-pagination
          @size-change="(e) => pageFun2(e, 'pageSize')"
          @current-change="(e) => pageFun2(e)"
          style="text-align: center"
          :page-sizes="pagination3.pageSizes"
          :page-size="priceParams2.pageSize"
          :current-page="priceParams2.pageNum"
          layout="total, prev, pager, next,sizes, jumper"
          :total="pagination3.total"
        >
        </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { getFinancialCheckList, editRemark, getFinancialCheckReportList,getOrderChangeReportList } from "../../service/money.js";
import { Config, formatDateTimer, tableColumn } from "../../utils/index.js";

export default {
  name: "Future",
  components: {},
  props: {},
  data() {
    return {
      tableColumn,
			formatDateTimer,
      remarkVisible: false, // 对账备注
      ruleForm: {
        settleId: '',
        settleRemark: ''
      },
			load: true,
      loading: false,
      params: {
        customerName: "",
        createTime: "",
        pageNum: 1,
        pageSize: 10,
		financialStatus: ''
      },
	  incomeStatus: [
	    {
	      value: "pending",
	      label: "待销售催款",
	    },
	    {
	      value: "confirming",
	      label: "待财务确认",
	    },
	    {
	      value: "complete",
	      label: "交易完成",
	    },
	  ],
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company, // 所属公司下拉
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      options: [
        {
          value: 2,
          label: "续签"
        },
        {
          value: 1,
          label: "新签"
        }
      ],
      options2: [
        {
          value: "generalAccount",
          label: "对公账户（总）"
        },
        {
          value: "publicAccount",
          label: "对公账户（分）"
        },
        {
          value: "taobao",
          label: "淘宝"
        },
        {
          value: "tiktok",
          label: "抖音"
        },
        {
          value: "pinduoduo",
          label: "拼多多"
        },
        {
          value: "bankCard",
          label: "银行卡"
        },
        {
          value: "alipay",
          label: "支付宝"
        },
        {
          value: "wechat",
          label: "微信"
        },
        {
          value: "cash",
          label: "现金"
        },
        {
          value: "other",
          label: "其他"
        }
      ],
      month: 1,
      tableData: [],
      textNum: 0,
      originalPriceTotal: 0,
      differencePriceAmountTotal: 0,
      actualPriceTotal: 0,
      collectionAmountTotal: 0,
      orderSettlePriceTotal: 0,
      changeSettlePriceTotal: 0,
      originalSettlePriceTotal: 0,
      newActualPriceTotal: 0,
      renewActualPriceTotal: 0,
      priceVisible: false,
      priceVisible2: false,
      loading2: false,
      loading3: false,
      priceData: [],
      priceData2: [],
      type: "",// 调价金额||调价月产能
      priceParams: {
        pageNum: 1,
        pageSize: 10
      },
      priceParams2: {
        pageNum: 1,
        pageSize: 10
      },
      pagination2: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      pagination3: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    'params.createTime'(val){
      let a = val.split('-')
      this.month = a[a.length-1]
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.rolesCompany && this.rolesCompany.length) {
      this.params.companyId = this.rolesCompany[0].id + "";
    }
    //设置默认日期
    this.defaultDate()
    this.getSaleList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
    openNewWin(url) {
      window.open(url)
    },
    editRemark(settleId) {
      this.ruleForm = {
        settleId: settleId,
        settleRemark: ''
      }
      if (this.$refs["ruleForm"]) this.$refs["ruleForm"].resetFields();
      this.remarkVisible = true
    },
    remarkIsOk () {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          let { code } = await editRemark(this.ruleForm)
          if ( code == 200 ) {
            this.$message.success("编辑成功")
            this.remarkVisible = false
            this.getSaleList();
          }
        } else {
          return false;
        }
      });
    },
    defaultDate() {
      let date = new Date();
      let year = date.getFullYear();
      let mon = date.getMonth() + 1
      mon = mon < 10 ? "0" + mon : mon;
      this.params.createTime = year +'-'+mon
      this.month = mon
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.getSaleList();
    },
    pageFun2(e, type) {
      // 分页
      if (type == "pageSize") {
        if(this.type == 'differencePrice'){
          this.priceParams.pageSize = e;
        }else{
          this.priceParams2.pageSize = e;
        }
      } else {
        if(this.type == 'differencePrice'){
          this.priceParams.pageNum = e;
        }else{
          this.priceParams2.pageNum = e;
        }
      }
      if(this.type == 'differencePrice'){
        this.openDifferencePrice(this.priceParams.orderId,this.type);
      }else {
        this.openChangeSettlePriceTotal(this.priceParams2.orderId,this.type);
      }
    },
    commonFun() {
      this.params.pageSize = 10;
      this.params.pageNum = 1;
      this.getSaleList();
    },
    resetBtn() {
      this.params = {
        companyId: this.rolesCompany[0].id + "",
        customerName: "",
        createTime: "",
        pageNum: 1,
        pageSize: 10
      };
      this.defaultDate()
      this.getSaleList();
    },
    async getSaleList() {
      this.loading = true;
      let params = { ...this.params };
      const { data } = await getFinancialCheckList(params);
      this.tableData = data.records;
      this.pagination.total = data.total;
      this.pagination.pageSize = data.size;
      if(data){
        if(data.originalPriceTotal){
          this.originalPriceTotal = data.originalPriceTotal.toFixed(2);
        }else{
          this.originalPriceTotal = 0
        }
        if(data.differencePriceAmountTotal){
          this.differencePriceAmountTotal = data.differencePriceAmountTotal.toFixed(2);
        }else{
          this.differencePriceAmountTotal = 0
        }
        if(data.actualPriceTotal){
          this.actualPriceTotal = data.actualPriceTotal.toFixed(2);
        }else{
          this.actualPriceTotal = 0
        }
        if(data.newActualPriceTotal){
          this.newActualPriceTotal = data.newActualPriceTotal.toFixed(2);
        }else{
          this.newActualPriceTotal = 0
        }
        if(data.renewActualPriceTotal){
          this.renewActualPriceTotal = data.renewActualPriceTotal.toFixed(2);
        }else{
          this.renewActualPriceTotal = 0
        }
        if(data.collectionAmountTotal){
          this.collectionAmountTotal = data.collectionAmountTotal.toFixed(2);
        }else{
          this.collectionAmountTotal = 0
        }
        if(data.orderSettlePriceTotal){
          this.orderSettlePriceTotal = data.orderSettlePriceTotal.toFixed(2);
        }else{
          this.orderSettlePriceTotal = 0
        }
        if(data.changeSettlePriceTotal){
          this.changeSettlePriceTotal = data.changeSettlePriceTotal.toFixed(2);
        }else{
          this.changeSettlePriceTotal = 0
        }
        if(data.originalSettlePriceTotal){
          this.originalSettlePriceTotal = data.originalSettlePriceTotal.toFixed(2);
        }else{
          this.originalSettlePriceTotal = 0
        }
      }
      this.textNum = "";
      this.loading = false;
			this.load = !this.load
    },
    goCustomerPool(row) {
      window.open(`/customer/customer-pool?row=${JSON.stringify(row)}&isEdit=false`);
      // this.$router.push({path: '/customer/customer-pool',query:{row: row}})
    },
    download() {
      // 导出
      let params = { ...this.params };
      delete params.pageNum;
      delete params.pageSize;
      let url = Config.api.url + "/financial/financialFlow/checkListExport";
      let paramArr = [];
      for (let item in params) {
        if (params[item]) {
          paramArr.push(item + "=" + params[item]);
        }
      }
      url = url + "?" + paramArr.join("&");
      window.location.href = url;
      console.log(window.location.href,'xiazailianjie');
    },
    async openDifferencePrice(orderId,type){ //订单调价金额弹窗
      this.type = type
      this.priceVisible = true
      this.loading2 = true
      
      this.priceParams.orderId = orderId
      let params = {...this.priceParams}
      params.companyId = this.params.companyId
      const { data } = await getOrderChangeReportList(params)
      this.loading2 = false
      this.priceData = data.list
      this.pagination2.total = data.total
    },
    async openChangeSettlePriceTotal(id,type){
      this.priceVisible2 = true
      this.type = type
      this.priceParams2.orderId = id
       let params = {...this.priceParams2}
       params.companyId = this.params.companyId
       params.createTime = this.params.createTime
       this.loading3 = true
       const { data } = await getFinancialCheckReportList(params)
       this.loading3 = false
       this.priceData2 = data.list
       this.pagination3.total = data.total
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  /deep/.el-table th.is-leaf, /deep/.el-table th.is-center {
    padding: 6px 0;
  }
  .title-container {
    display: flex;
    justify-content: space-between;
    color: rgba(16, 16, 16, 100);
    font-size: 24px;
    text-align: left;
  }
  .line {
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #e6e6e6;
  }
  .common-screen-container {
    margin-top: 44px;
    margin-left: 32px;
  }
  .gap {
    height: 16px;
    background-color: #ededed;
  }
  .el-table {
    // width: 98% !important;
    margin: 16px 0;
    thead {
      color: #101010 !important;
      .cell {
        color: #101010 !important;
      }
    }
  }
  .orange {
    color: #f09009;
    font-size: 12px;
  }
}
</style>

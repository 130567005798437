import fetch from "./xhr/index";

/**
 * 财务进账报表接口
 * @param
 */
export const getIncomeTitle = (param) => fetch("GET", "/financial/financialFlow/getIncomeStatement", param);

/**
 * 财务进账 列表
 * @param
 */
export const getRenewalList = param => fetch("GET", "/cms/cmsCustomerOrder/getRenewalList", param);

/**
 * 订单id获取流水 列表
 * @param
 */
export const getIncomeDetail = param => fetch("GET", "/financial/financialFlow/getFlowList", param);

/**
 * 订单id获取流水 列表
 * @param
 */
export const selectIncomeDetail = param => fetch("GET", "/cms/orderDistribute/selectIncomeDetail", param);

/**
 * 获取流水详情
 * @param
 */
export const getDetail = param => fetch("GET", "/financial/financialFlow/getFlowDetail", param);

/**
 * 财务确认收款
 * @param
 */
export const confirmMoney = param => fetch("GET", "/financial/financialFlow/collectionComfirm", param);

/**
 * 出账列表接口
 * @param
 */
export const payoutList = param => fetch("GET", "/financial/financialFlow/paymentList", param);

/**
 * 出款列表报表
 * @param
 */
export const payoutTitle = param => fetch("GET", "/financial/financialFlow/paymentRecord", param);


/**
 * 新增出款
 * @param
 */
export const newPayout = param => fetch("POST", "/financial/financialFlow/payment", param);

/**
 * 附件上传接口
 * @param
 */
export const payoutUpload = param => fetch("POST", "/financial/financialFlow/uploadAttachment", param);

/**
 * 附件删除接口
 * @param
 */
export const payoutdelete = param => fetch("GET", "/financial/financialFlow/deleteAttachment", param);


/**
 * 手动调价通知人下拉
 * @param
 */
export const orderNoticeList = (param) => fetch("POST", `/cms/cmsCustomerOrder/noticeList`, param);

/**
 * 获取出款详情
 * @param
 */
export const payoutdetail = param => fetch("GET", "/financial/financialFlow/paymentDetail", param);

/**
 * 作废流水接口
 * @param
 */
export const deleteFlow = param => fetch("GET", "/financial/financialFlow/deleteFlow", param);

/**
 * 财务对账列表报表
 * @param
 */
export const checkTitle = param => fetch("GET", "/financial/financialFlow/getReconciliationRecord", param);

/**
 * 财务对账列表
 * @param
 */
export const checkList = param => fetch("GET", "/financial/financialFlow/getReconciliationList", param);

/**
 * 获取历史欠款订单列表
 * @param
 */
export const getHistoryList = param => fetch("GET", "/financial/financialFlow/getHistoryUnCollectionList", param);

/**
 * 获取上次出账的收款人信息
 * @param
 */
export const historyPeople = param => fetch("GET", "/financial/financialFlow/getHistoryExpenditure", param);

/**
 * 确认收账
 * @param
 */
export const updateFinancialFlow = param => fetch("GET", "/cms/orderDistribute/updateFinancialFlow", param);


/**
 * 平台--工作日志
 * @parama
 */
export const orderWorkLog = param => fetch("GET", "/cms/orderDistribute/getLogList", param);


/**
 * 平台-- 附件删除
 * @param
 */
export const fileDelete = (param) => fetch("POST", `/cms/orderDistribute/fileDelete`, param);

/**
 * 平台-- 附件上传
 * @param
 */
export const fileUpload = (param) => fetch("POST", `/cms/orderDistribute/fileUpload`, param);


/**
 * 平台--出账列表--出账作废
 * @parama
 */
export const deleteFinancialFlow = param => fetch("GET", "/cms/orderDistribute/deleteFinancialFlow", param);

/**
 * 预测
 * @param
 */
export const getFinancialForecast = param => fetch("GET", "/financial/financialFlow/getFinancialForecast", param);

/**
 * 财务销售对账列表
 * @param
 */
export const getFinancialCheckList = param => fetch("GET", "/financial/financialFlow/getFinancialCheckList", param);

/**
 * 财务进账列表---接单弹窗字段
 * @param
 */
export const getFinancialReportList = param => fetch("GET", "/cms/orderDistribute/getFinancialReportList", param);

/**
 * 财务流水列表---总收入弹窗字段
 * @param
 */
export const getFinancialReportList2 = param => fetch("GET", "/financial/financialFlow/getFinancialReportList", param);

/**
 * 财务对账列表---设置对账备注
 * @param
 */
export const editRemark = param => fetch("GET", "/financial/financialFlow/setSettleRemark", param);

/**
 * 财务---订单调价记录列表
 * @param
 */
export const changePrice = param => fetch("GET", "/orderTask/task/getChangePriceList", param);

/**
 * 财务销售对账列表 订单调价产能详情
 * @param
 */
export const getFinancialCheckReportList = param => fetch("GET", "/financial/financialFlow/getFinancialCheckReportList", param);

/**
 * 财务销售对账列表 订单调价金额详情
 * @param
 */
export const getOrderChangeReportList = param => fetch("GET", "/financial/financialFlow/getOrderChangeReportList", param);
